// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-building-a-vanilla-js-image-zoomer-mdx": () => import("../src/pages/posts/building-a-vanilla-js-image-zoomer.mdx" /* webpackChunkName: "component---src-pages-posts-building-a-vanilla-js-image-zoomer-mdx" */),
  "component---src-pages-posts-questions-applying-swe-job-mdx": () => import("../src/pages/posts/questions-applying-swe-job.mdx" /* webpackChunkName: "component---src-pages-posts-questions-applying-swe-job-mdx" */),
  "component---src-pages-posts-speeding-up-page-load-with-early-flush-mdx": () => import("../src/pages/posts/speeding-up-page-load-with-early-flush.mdx" /* webpackChunkName: "component---src-pages-posts-speeding-up-page-load-with-early-flush-mdx" */),
  "component---src-pages-posts-tips-for-building-universal-javascript-apps-mdx": () => import("../src/pages/posts/tips-for-building-universal-javascript-apps.mdx" /* webpackChunkName: "component---src-pages-posts-tips-for-building-universal-javascript-apps-mdx" */)
}

